<template>
    <div class="bodyapp">
        <div class="mainapp">
            <div class="studyleft">
                <div v-for="item in subitem" :key="item">
                    <div class="boxitem">
                        {{item}}
                    </div>
                </div>
            </div>
            <div class="studyright">
                아카이브
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            subitem:[
                        '알고리즘(algorithm)',
                        '자격증(Certification)'
                    ],
        }
    },
    components: {
    },
    methods:{
    }
}
</script>

<style scoped>
.bodyapp{
    height: 100vh;
}
.mainapp{
    margin: 0 15vw;
    background-color: rgb(178, 105, 246);
    display: flex;
    position: relative;
    justify-content: space-around;
}
.studyleft{
    width: 270px;
    background-color: rgb(124, 32, 32);
    min-width: 270px;
    height: 100%;
    flex:1;
}
.studyright{
    flex:3;
}
.boxitem{
    margin: 10px 15px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color:rgb(223, 223, 116);
}
</style>